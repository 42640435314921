<template>
    <div id="launch" class="d-flex flex-column justify-content-center align-items-center"
         :class="{ 'blur-background': goLive }"
         style="padding: 1rem; background: #FFEFE9; height: 100vh">
        <div class="text-center align-items-center text-error mb-3 mt-3">
            <div>कर्णाली प्रदेश सरकार</div>
            <div style="font-size: 24px">उद्योग, पर्यटन, वन तथा वातावरण मन्त्रालय</div>
            <div style="font-size: 16px">कर्णाली सम्वृद्धिको आधार, पर्यटनको विकास र पूर्वाधार</div>
            <div>वीरेन्द्रनगर, सुर्खेत</div>
        </div>

        <h1 class="mt-5 mb-5 text-error">‘गन्तव्य कर्णाली’</h1>
        <!--        <img :src="logo" class="img-fluid" alt="mis logo">-->
        <div class="mb-5">
            <b-btn class="btn btn-danger btn-rounded" @click.prevent="goWebsiteLive">Go Live</b-btn>
        </div>
        <div class="clock" v-if="showClock">
            <span id="seconds" class="countdown-number"></span>
        </div>
        <!-- Video element -->
        <video ref="promoVideo" id="promoVideo" class="promo-video" @ended="onVideoEnd" v-show="showVideo" width="100%"
               height="100%" controls>
            <source :src="launchVideo" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown'
import confetti from 'canvas-confetti';

export default {
    name: "Countdown",
    components: {FlipCountdown},
    data() {
        return {
            logo: '/images/logo/logo.png',
            goLive: false,
            clappingSound: 'images/clap.wav',
            launchVideo: 'images/launch_video.mp4',
            showClock: true,
            showVideo: false
        }
    },
    methods: {
        startFireWork() {
            let duration = 8 * 1000;
            let animationEnd = Date.now() + duration;
            let defaults = {startVelocity: 30, spread: 360, ticks: 60, zIndex: 0};

            function randomInRange(min, max) {
                return Math.random() * (max - min) + min;
            }

            let interval = setInterval(function () {
                let timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    return clearInterval(interval);
                }

                let particleCount = 50 * (timeLeft / duration);
                confetti({...defaults, particleCount, origin: {x: randomInRange(0.1, 0.3), y: Math.random() - 0.2}});
                confetti({...defaults, particleCount, origin: {x: randomInRange(0.7, 0.9), y: Math.random() - 0.2}});
            }, 250);
        },
        startSchoolPride() {
            let end = Date.now() + (8 * 1000);
            let colors = ['#bb0000', '#ffffff'];

            (function frame() {
                confetti({
                    particleCount: 2,
                    angle: 60,
                    spread: 55,
                    origin: {x: 0},
                    colors: colors
                });
                confetti({
                    particleCount: 2,
                    angle: 120,
                    spread: 55,
                    origin: {x: 1},
                    colors: colors
                });

                if (Date.now() < end) {
                    requestAnimationFrame(frame);
                }
            }());
        },
        redirectToHomeScreen() {
            // window.location.replace('https://travel.karnali.gov.np/?goLive=true')
        },
        goWebsiteLive() {
            this.goLive = true
            let clock = document.getElementById('seconds');
            let secondsRemaining = 3;
            clock.innerText = secondsRemaining;
            this.showClock = true
            clock.classList.add('animation');
            const myInterval = setInterval(() => {
                secondsRemaining--;
                clock.innerText = secondsRemaining;
                if (!secondsRemaining) {
                    this.showClock = false
                    this.goLive = false
                    this.startFireWork()
                    this.startSchoolPride()
                    this.redirectToHomeScreen()

                    // Create an audio element
                    const audio = new Audio(this.clappingSound);

                    // Play the clapping sound
                    audio.play();

                    this.playVideo();

                    stopAnimation();
                }

            }, 1000)

            function stopAnimation() {
                clock.classList.remove('animation');
                clearInterval(myInterval);
            }

        },
        playVideo() {
            this.showVideo = true
            const video = this.$refs.promoVideo;
            video.style.display = 'block';
            video.play();
        },
        onVideoEnd() {

        },
        removeParentClass() {
            const childElement = document.getElementById('launch');
            const parentElement = childElement.parentElement;
            parentElement.classList.remove('py-4');
        }
    },
    mounted() {
        this.removeParentClass()
    }
}
</script>

<style>

.text-error {
    color: #c1282f !important;
}

.blur-background {
    //filter: blur(5px);
    //transition: filter 0.5s ease;
}

.clock {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.animation {
    animation: 1s fade-out ease;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}

#seconds {
    font-size: 20rem;
}

.promo-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    width: 80%;
}

@keyframes fade-out {
    from {
        opacity: 1
    }
    99% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}
</style>
