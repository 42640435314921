<template>
    <div class="col-lg-12">
        <div class="row justify-content-center overflow-hidden">
            <div class="col-md-8 col-lg-8 col-xl-8">
                <div class="card overflow-hidden bg-white">
                    <div class="card-body pt-0">
                        <b-form class="p-2">
                            <b-form-group id="destination-group" label-for="name" class="mb-1">
                                <label>{{ $t('common.destination_name') }}<span class="text-danger">*</span></label>
                                <b-form-input
                                    id="name"
                                    v-model="form.name"
                                    name="name"
                                    type="text"
                                    placeholder="Enter Destination Name"
                                    :class="{ 'is-invalid':$v.form.name.$error }"
                                ></b-form-input>
                                <div v-if="$v.form.name.$error" class="invalid-feedback">
                                      <span v-if="!$v.form.name.required">
                                          {{ $t('common.is_required') }}
                                      </span>
                                </div>
                            </b-form-group>
                            <div class="row">
                                <div
                                    :class="{'col-12': !getSubCategories().length && !getSubSubCategories().length, 'col-6': getSubCategories().length > 0 && !getSubSubCategories().length, 'col-4': getSubSubCategories().length > 0}">
                                    <b-form-group id="category-group" label-for="category" class="mb-1">
                                        <label>{{ $t('common.category') }}<span class="text-danger">*</span></label>
                                        <b-form-select
                                            v-model="form.category"
                                            :options="categories"
                                            :class="{ 'is-invalid':$v.form.category.$error }"
                                            text-field="name"
                                            value-field="id"
                                            class="form-control">
                                        </b-form-select>
                                        <div v-if="$v.form.category.$error" class="invalid-feedback">
                                          <span v-if="!$v.form.category.required">
                                              {{ $t('common.is_required') }}
                                          </span>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div
                                    :class="{ 'col-6':  !getSubSubCategories().length, 'col-4': getSubSubCategories().length > 0}"
                                    v-if="getSubCategories().length > 0">
                                    <b-form-group id="category-group" label-for="category" class="mb-1">
                                        <label>{{ $t('common.sub_category') }}</label>
                                        <b-form-select
                                            v-model="form.sub_category"
                                            :options="getSubCategories()"
                                            text-field="name"
                                            value-field="id"
                                            class="form-control">
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-4" v-if="getSubSubCategories().length > 0">
                                    <b-form-group id="category-group" label-for="category" class="mb-1">
                                        <label>{{ $t('common.sub_subcategory') }}</label>
                                        <b-form-select
                                            v-model="form.sub_subcategory"
                                            :options="getSubSubCategories()"
                                            text-field="name"
                                            value-field="id"
                                            class="form-control">
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-6">
                                    <b-form-group id="category-group" label-for="category" class="mb-1">
                                        <label>{{ $t('common.districts') }}<span class="text-danger">*</span></label>
                                        <b-form-select
                                            v-model="form.district"
                                            :options="districts"
                                            text-field="name_ne"
                                            value-field="id"
                                            :class="{ 'is-invalid':$v.form.district.$error }"
                                            placeholder="Select"
                                            class="form-control">
                                        </b-form-select>
                                        <div v-if="$v.form.district.$error" class="invalid-feedback">
                                          <span v-if="!$v.form.district.required">
                                              {{ $t('common.is_required') }}
                                          </span>
                                        </div>
                                    </b-form-group>
                                </div>
                                <div class="col-6">
                                    <b-form-group id="destination-group" label-for="mobile" class="mb-1">
                                        <label>{{ $t('common.contact_no') }}</label>
                                        <b-form-input
                                            id="mobile"
                                            v-model="form.mobile"
                                            name="mobile"
                                            type="text"
                                            placeholder="Contact No."
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>
                            <b-form-group id="destination-group" label-for="name" class="mb-1">
                                <label>{{ $t('common.summary') }}<span class="text-danger">*</span></label>
                                <b-form-textarea
                                    id="name"
                                    v-model="form.summary"
                                    name="name"
                                    type="text"
                                    placeholder="Short summary of destination"
                                    :class="{ 'is-invalid':$v.form.summary.$error }"
                                ></b-form-textarea>
                                <div v-if="$v.form.summary.$error" class="invalid-feedback">
                                      <span v-if="!$v.form.summary.required">
                                          {{ $t('common.is_required') }}
                                      </span>
                                </div>
                            </b-form-group>
                            <b-form-group id="destination-group" label-for="description" class="mb-1">
                                <label>{{ $t('common.description') }}<span class="text-danger">*</span></label>
                                <b-form-textarea
                                    id="description"
                                    v-model="form.description"
                                    name="description"
                                    type="text"
                                    placeholder="Detail description of destination"
                                    rows="5"
                                    :class="{ 'is-invalid':$v.form.description.$error }"
                                ></b-form-textarea>
                                <div v-if="$v.form.description.$error" class="invalid-feedback">
                                      <span v-if="!$v.form.description.required">
                                          {{ $t('common.is_required') }}
                                      </span>
                                </div>
                            </b-form-group>
                        </b-form>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-end align-items-center mb-1">
                            <b-button variant="primary" @click.prevent="onFilePick">Upload</b-button>
                            <input
                                id="avatar"
                                type="file"
                                multiple
                                accept="image/*"
                                style="display: none"
                                ref="upload"
                                @change="onMediaPicked">

                        </div>
                        <div class="row" v-if="previewAttachments.length">
                            <div class="form-group col-lg-3 col-md-3 col-sm-3 mb-1"
                                 v-for="(preview, previewIndex) in previewAttachments">
                                <div class="avatar-xxl">
                                    <img
                                        :src="preview.url"
                                        width="100%" height="225"
                                        class="rounded me-2 object-fit-cover" alt="">
                                </div>
                                <div class="d-flex justify-content-center align-items-center mt-1">
                                    <b-button class="btn btn-danger" @click.prevent="removeAttachment(previewIndex)">
                                        Remove
                                    </b-button>
                                </div>
                            </div>
                        </div>

                        <div v-else>
                            <p>No images uploaded</p>
                        </div>
                    </div>
                </div>
                <!--    Checkpoints  -->
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-content-center">
                            <div class="mb-1 font-medium-1">{{ $t('common.checkpoint') }}</div>
                            <b-button variant="primary" @click="addCheckpoint">
                                <i data-feather='plus'></i>
                            </b-button>
                        </div>
                        <div class="row" v-for="(point, pointIndex) in form.checkpoints">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>{{ $t('common.point_name') }}</label>
                                    <input name="point" v-model="point.point" class="form-control"
                                           placeholder="Point Name">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>{{ $t('common.distance') }}</label>
                                    <input name="distance" v-model="point.distance" class="form-control"
                                           placeholder="Eg: 1Km, 5Km, 500M">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label>{{ $t('common.time_to_reach') }}</label>
                                    <input name="time" v-model="point.time" class="form-control"
                                           placeholder="Eg: 3Hrs, 1.5Hrs">
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label>{{ $t('common.altitude') }}</label>
                                    <input name="altitude" v-model="point.altitude" class="form-control"
                                           placeholder="Eg: 2000M, above sea level">
                                </div>
                            </div>
                            <div class="col-md-1 d-flex justify-content-center align-content-center"
                                 style="margin-top: 1rem">
                                <div class="btn-group">
                                    <b-button variant="danger" @click.prevent="removeCheckpoint(pointIndex)">
                                        <i data-feather='trash'></i>
                                    </b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-4 col-xl-4">
                <div class="card overflow-hidden bg-white">
                    <div class="card-body pt-0">
                        <div class="form-group col-12 mt-1">
                            <h5>{{ $t('common.select_your_business_address_in_goggle_map') }}
                                <span class="text-danger">*</span>
                            </h5>
                            <div class="row mb-1">
                                <div class="col-6">
                                    <label>{{ $t('common.latitude') }}</label>
                                    <input type="text" v-model="form.location.latitude" class="form-control"
                                           placeholder="Latitude">
                                </div>
                                <div class="col-6">
                                    <label>{{ $t('common.longitude') }}</label>
                                    <input type="text" v-model="form.location.longitude" class="form-control"
                                           placeholder="Longitude">
                                </div>
                            </div>
                            <GmapMap
                                :center="center"
                                :zoom="14"
                                map-style-id="roadmap"
                                :options="mapOptions"
                                style="width: 100%; height: 50vmin"
                                ref="mapRef"
                                @click="handleMapClick"
                            >
                                <GmapMarker
                                    :position="marker.position"
                                    :clickable="true"
                                    :draggable="true"
                                    @drag="handleMarkerDrag"
                                    @click="panToMarker"
                                />
                            </GmapMap>
                        </div>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->
            </div>
        </div>

        <div class="d-flex justify-content-end align-items-center">
            <b-button variant="warning" class="me-2"
                      @click.prevent="editMode ? updateDestination('draft') : storeDestination('draft')">Draft
            </b-button>
            <b-button variant="primary" class="me-2"
                      @click.prevent="editMode ? updateDestination('publish'): storeDestination('publish')">Publish
            </b-button>
        </div>
    </div>

</template>

<script>
import {required} from "vuelidate/lib/validators";
import i18n from "../../i18n";

export default {
    data() {
        return {
            form: {
                name: null,
                summary: null,
                description: null,
                mobile: null,
                category: null,
                district: null,
                sub_category: null,
                sub_subcategory: null,
                attachments: [],
                location: {
                    latitude: null,
                    longitude: null
                },
                checkpoints: []
            },
            businessSizeOptions: ['Normal', 'Medium', 'High'],
            previewAttachments: [],
            marker: {position: {lat: 28.601181805179927, lng: 81.62356400177173}},
            center: {lat: 28.6011, lng: 81.623},
            mapOptions: {
                disableDefaultUi: false,
            },
            languages: [
                {
                    flag: "images/flags/us.jpg",
                    language: "en",
                    title: "English",
                },
                {
                    flag: "images/flags/nepal.png",
                    language: "np",
                    title: "Nepali",
                },
            ],
            logo: "images/logo.png",
            lan: i18n.locale,
            text: null,
            flag: null,
            value: null,
            submitting: false,
            editMode: false
        };
    },
    validations: {
        form: {
            name: {required},
            summary: {required},
            description: {required},
            category: {required},
            district: {required},
            mobile: {required},
        },
    },
    props: ['categories', 'districts', 'destination'],
    methods: {
        getSubCategories() {
            if (!this.form.category || !Array.isArray(this.categories)) {
                return [];
            }

            const category = this.categories.find(x => x.id === this.form.category);
            return category ? category.children : [];
        },
        getSubSubCategories() {
            if (!this.form.sub_category || !Array.isArray(this.categories)) {
                return [];
            }

            const category = this.categories.find(x => x.id === this.form.category);
            if (!category || !Array.isArray(category.children)) {
                return [];
            }

            const subCategory = category.children.find(x => x.id === this.form.sub_category);
            if (!subCategory || !Array.isArray(subCategory.children)) {
                return [];
            }

            return subCategory.children;
        },
        getPreviewUrl(index) {
            const previewAttachment = this.previewAttachments.find((item) => item.index === index);
            return previewAttachment ? previewAttachment.url : 'https://via.placeholder.com/500';
        },
        onFilePick() {
            this.$refs.upload.click();
        },
        onMediaPicked(e) {
            const files = e.target.files;
            if (!files) {
                this.$refs.upload.value = null;
                return;
            }
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fr = new FileReader();
                fr.readAsDataURL(file);
                fr.addEventListener('load', () => {
                    this.form.attachments.push({index: this.selectedIndex, file: file});
                    this.previewAttachments.push({index: this.selectedIndex, url: fr.result});
                })
            }
        },
        removeAttachment(index) {
            this.previewAttachments.splice(index, 1)
            this.form.attachments.splice(index, 1)
        },
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.marker.position = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                this.panToMarker();
            });
        },

        //sets the position of marker when dragged
        handleMarkerDrag(e) {
            this.marker.position = {lat: e.latLng.lat(), lng: e.latLng.lng()};
            this.form.location = {
                latitude: e.latLng.lat(),
                longitude: e.latLng.lng()
            };
        },
        //Moves the map view port to marker
        panToMarker() {
            this.$refs.mapRef.panTo(this.marker.position);
            this.$refs.mapRef.setZoom(18);
        },
        //Moves the marker to click position on the map
        handleMapClick(e) {
            this.marker.position = {lat: e.latLng.lat(), lng: e.latLng.lng()};
            this.form.location = {
                latitude: e.latLng.lat(),
                longitude: e.latLng.lng()
            };
        },
        getCurrentLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    this.successCallback,
                    this.errorCallback
                );
            } else {
                this.error = 'Geolocation is not supported by this browser.';
            }
        },
        successCallback(position) {
            const {latitude, longitude} = position.coords
            this.form.location = {
                latitude: latitude,
                longitude: longitude
            };
            this.marker.position = {lat: latitude, lng: longitude}
        },
        errorCallback(error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    this.error = 'User denied the request for Geolocation.';
                    break;
                case error.POSITION_UNAVAILABLE:
                    this.error = 'Location information is unavailable.';
                    break;
                case error.TIMEOUT:
                    this.error = 'The request to get user location timed out.';
                    break;
                case error.UNKNOWN_ERROR:
                    this.error = 'An unknown error occurred.';
                    break;
            }
        },
        addCheckpoint() {
            const points = {
                point: '',
                distance: '',
                time: '',
                altitude: ''
            }
            this.form.checkpoints.push(points)
        },
        removeCheckpoint(index) {
            this.form.checkpoints.splice(index, 1)
        },
        storeDestination(type) {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$awn.alert('Error! Please fill all the required fields');
                return;
            }

            this.submitting = true
            const {latitude, longitude} = this.form.location
            let formData = new FormData();

            this.form.attachments.forEach((item, index) => {
                formData.append(`attachment${index + 1}`, item.file);
            })

            formData.append('name', this.form.name)
            formData.append('type', type)
            formData.append('summary', this.form.summary)
            formData.append('description', this.form.description)
            formData.append('category', this.form.category)
            formData.append('district', this.form.district)
            formData.append(
                'category',
                this.form.sub_subcategory ? this.form.sub_subcategory :
                    (this.form.sub_category ? this.form.sub_category :
                        this.form.category)
            );
            formData.append('mobile', this.form.mobile)
            formData.append('location', JSON.stringify({latitude: latitude, longitude: longitude}))
            formData.append('checkpoints', JSON.stringify(this.form.checkpoints))

            axios.post('/admin/items', formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(response => {
                const {message} = response.data
                this.$awn.success(message);
                window.location.href = '/admin/items'
            }).catch(error => {
                this.$awn.alert(error.response.data.message ? error.response.data.message : error.message);
            }).finally(() => {
                this.submitting = false
            })
        },
        editDestination() {
            if (this.destination) {
                this.editMode = true

                this.form.name = this.destination.name
                this.form.summary = this.destination.short_description
                this.form.description = this.destination.description
                this.form.mobile = this.destination.mobile
                this.form.district = this.destination.district_id
                this.form.location.latitude = this.destination.latitude
                this.form.location.longitude = this.destination.longitude
                this.form.checkpoints = this.destination.checkpoints
                this.previewAttachments = this.destination.attachments

                if (this.destination.category_id) {
                    const index = this.categories.findIndex(x => x.id === Number.parseInt(this.destination.category_id));

                    if (index !== -1) {
                        this.form.category = this.categories[index].id
                        const subCategoryIndex = this.categories[index].children.findIndex(x => x.id === this.destination.category_id);
                        this.categories[index].children.splice(subCategoryIndex, 1)
                    } else {
                        const subCategory = this.findItemById(this.categories, Number.parseInt(this.destination.category_id))

                        if (!subCategory.hasOwnProperty('children') && subCategory.parent_id !== null) {
                            this.form.sub_subcategory = subCategory.id;
                        }

                        const cat = this.findItemById(this.categories, Number.parseInt(subCategory.parent_id))
                        if (cat.hasOwnProperty('children') && cat.parent_id !== null) {
                            this.form.sub_category = cat.id;
                        } else if (cat.hasOwnProperty('children') && cat.parent_id === null) {
                            this.form.sub_category = subCategory.id
                            this.form.category = cat.id;
                        }

                        if (this.form.category === null) {
                            this.form.category = this.findItemById(this.categories, Number.parseInt(cat.parent_id)).id
                        }
                    }
                }

                this.marker.position.lat = this.destination.latitude ? parseFloat(this.destination.latitude) : 28.6011
                this.marker.position.lng = this.destination.longitude ? parseFloat(this.destination.longitude) : 81.623

                this.center.lat = this.destination.latitude ? parseFloat(this.destination.latitude) : 28.6011
                this.center.lng = this.destination.longitude ? parseFloat(this.destination.longitude) : 81.623
            }
        },
        findItemById(items, parent_id) {
            for (const item of items) {
                if (item.id === parent_id) {
                    return item;
                }
                if (item.children && item.children.length > 0) {
                    const foundItem = this.findItemById(item.children, parent_id);
                    if (foundItem) {
                        return foundItem; // Return the found item if exists in children
                    }
                }
            }
        },
        updateDestination(type) {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$awn.alert('Error! Please fill all the required fields');
                return;
            }

            this.submitting = true
            const {latitude, longitude} = this.form.location
            let formData = new FormData();

            this.form.attachments.forEach((item, index) => {
                formData.append(`attachment${index + 1}`, item.file);
            })

            formData.append('name', this.form.name)
            formData.append('type', type)
            formData.append('summary', this.form.summary)
            formData.append('description', this.form.description)
            formData.append('category', this.form.category)
            formData.append('district', this.form.district)
            formData.append(
                'category',
                this.form.sub_subcategory ? this.form.sub_subcategory :
                    (this.form.sub_category ? this.form.sub_category :
                        this.form.category)
            );
            formData.append('mobile', this.form.mobile)
            formData.append('location', JSON.stringify({latitude: latitude, longitude: longitude}))
            formData.append('checkpoints', JSON.stringify(this.form.checkpoints))

            axios.post(`/admin/items/${this.destination.id}?_method=PATCH`, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(response => {
                const {message} = response.data
                this.$awn.success(message);
                window.location.href = '/admin/items'
            }).catch(error => {
                this.$awn.alert(error.response.data.message ? error.response.data.message : error.message);
            }).finally(() => {
                this.submitting = false
            })
        },
    },
    mounted() {
        this.getCurrentLocation()
        this.editDestination();
    },
};
</script>
