<template>
    <div class="col-lg-12">
        <div class="row justify-content-center overflow-hidden">
            <div class="listing_title">
                <h3>{{ $t('common.for_app_listing') }}</h3>
            </div>
            <div class="col-md-8 col-lg-6 col-xl-5" style="margin-top: 4rem">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <a href="/" class="logo logo-dark">
                <span class="logo-sm">
                  <img :src="logo" alt height="36"/>
                </span>
                    </a>
                    <b-dropdown variant="white" right toggle-class="header-item">
                        <template v-slot:button-content>
                            <img class :src="flag" alt="language" height="16"/>
                            {{ text }}
                        </template>
                        <b-dropdown-item
                            class="notify-item"
                            v-for="(entry, i) in languages"
                            :key="`Lang${i}`"
                            :value="entry"
                            @click="setLanguage(entry.language, entry.title, entry.flag)"
                            :class=" {'active' : lan === entry.language}"
                        >
                            <img :src="`${entry.flag}`" alt="user-image" class="me-1" height="12"/>
                            <span class="align-middle">{{ entry.title }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
                <div class="card overflow-hidden bg-white">
                    <div class="card-body pt-0">
                        <b-form class="p-2">
                            <slot/>
                            <b-form-group id="business-group" label-for="name"
                                          class="mb-3">
                                <label>{{ $t('common.business_name') }}<span class="text-danger">*</span>
                                </label>
                                <b-form-input
                                    id="name"
                                    v-model="form.name"
                                    name="name"
                                    type="text"
                                    placeholder="Enter Business Name"
                                    :class="{ 'is-invalid':$v.form.name.$error }"
                                ></b-form-input>
                                <div v-if="$v.form.name.$error" class="invalid-feedback">
                              <span v-if="!$v.form.name.required">
                                  {{ $t('common.is_required') }}
                              </span>
                                </div>
                            </b-form-group>

                            <b-form-group id="category-group" label-for="name"
                                          class="mb-3">
                                <label>{{ $t('common.category') }}<span class="text-danger">*</span></label>
                                <b-form-select
                                    v-model="form.category"
                                    :options="categories"
                                    text-field="name"
                                    value-field="id"
                                    :class="{ 'is-invalid':$v.form.category.$error }"
                                    class="form-control">
                                </b-form-select>
                                <div v-if="$v.form.category.$error" class="invalid-feedback">
                                      <span v-if="!$v.form.category.required">
                                          {{ $t('common.is_required') }}
                                      </span>
                                </div>
                            </b-form-group>

                            <div class="row">
                                <div :class="shouldHavePrice ? 'col-md-4' : 'col-md-12'">
                                    <b-form-group id="business-size-group" :label="$t('common.facility')"
                                                  label-for="facility"
                                                  class="mb-3">
                                        <b-form-select v-model="form.business_size" :options="businessSizeOptions"
                                                       class="form-control"></b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-md-4" v-if="shouldHavePrice">
                                    <b-form-group id="price-min-group" :label="$t('common.room_min_price')"
                                                  label-for="min-price"
                                                  class="mb-3">
                                        <b-form-input
                                            id="min-price"
                                            v-model="form.price.min"
                                            name="min_price"
                                            type="number"
                                            min="0"
                                            placeholder="Min"
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-4" v-if="shouldHavePrice">
                                    <b-form-group id="price-max-group" :label="$t('common.room_max_price')"
                                                  label-for="max-price"
                                                  class="mb-3">
                                        <b-form-input
                                            id="max-price"
                                            v-model="form.price.max"
                                            name="max_price"
                                            type="number"
                                            min="0"
                                            placeholder="Max"
                                        ></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group id="contact-group" label-for="phone"
                                          class="mb-3">
                                <label>{{ $t('common.contact') }}<span class="text-danger">*</span></label>
                                <b-form-input
                                    id="phone"
                                    name="phone"
                                    v-model="form.contact"
                                    type="text"
                                    placeholder="Enter Phone/Mobile No."
                                    :class="{ 'is-invalid':$v.form.contact.$error }"
                                ></b-form-input>
                                <div v-if="$v.form.category.$error" class="invalid-feedback">
                              <span v-if="!$v.form.category.required">
                                  {{ $t('common.is_required') }}
                              </span>
                                </div>
                            </b-form-group>

                            <div class="row">
                                <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
                                    <label>{{ $t('common.business_identification_photo') }}<span
                                        class="text-danger">*</span></label>
                                    <div class="avatar avatar-xxl">
                                        <img
                                            :src="previewAttachments.length ? getPreviewUrl(0) : 'https://via.placeholder.com/500'"
                                            width="100%" height="270"
                                            @click.prevent="onFilePick(0)"
                                            class="rounded me-2 object-fit-cover" alt="">
                                        <input
                                            id="avatar"
                                            type="file"
                                            style="display: none"
                                            ref="upload_0"
                                            @input="onMediaPicked">
                                    </div>
                                </div>
                                <div class="form-group col-lg-6 col-md-6 col-sm-6 mb-2">
                                    <label>
                                        {{ $t('common.verification_document_pan_registration') }}
                                        <span class="text-danger">*</span></label>
                                    <div class="avatar avatar-xxl">
                                        <img
                                            :src="previewAttachments.length ? getPreviewUrl(1) : 'https://via.placeholder.com/500'"
                                            width="100%" height="270"
                                            @click.prevent="onFilePick(1)"
                                            class="rounded me-2 object-fit-cover" alt="">
                                        <input
                                            id="avatar"
                                            type="file"
                                            style="display: none"
                                            ref="upload_1"
                                            @input="onMediaPicked">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group col-12 mt-5">
                                <h5>{{ $t('common.select_your_business_address_in_goggle_map') }}
                                    <span class="text-danger">*</span>
                                </h5>
                                <GmapMap
                                    :center="center"
                                    :zoom="14"
                                    map-style-id="roadmap"
                                    :options="mapOptions"
                                    style="width: 100%; height: 50vmin"
                                    ref="mapRef"
                                    @click="handleMapClick"
                                >
                                    <GmapMarker
                                        :position="marker.position"
                                        :clickable="true"
                                        :draggable="true"
                                        @drag="handleMarkerDrag"
                                        @click="panToMarker"
                                    />
                                </GmapMap>
                            </div>

                            <div class="mt-4 d-grid">
                                <b-button @click="storeBusiness" variant="primary" class="btn-block" :disabled="submitting">
                                    <b-spinner small v-if="submitting"></b-spinner>
                                    {{ $t('common.submit') }}
                                </b-button>
                            </div>
                        </b-form>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->

                <div class="mt-5 text-center">
                </div>
            </div>
            <!-- end col -->
            <div class="col-md-4 col-lg-4 col-xl-4 fb_timeline">
                <iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fdestinationkarnali&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=426568464644092"
                    width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
        </div>
    </div>

</template>

<script>
import {required} from "vuelidate/lib/validators";
import i18n from "../../i18n";

export default {
    data() {
        return {
            form: {
                name: null,
                category: null,
                price: {
                    min: null,
                    max: null,
                },
                business_size: 'Normal',
                contact: null,
                attachments: [],
                location: null
            },
            categories: [],
            businessSizeOptions: ['Normal', 'Medium', 'High'],
            previewAttachments: [],
            marker: {position: {lat: 28.601181805179927, lng: 81.62356400177173}},
            center: {lat: 28.6011, lng: 81.623},
            mapOptions: {
                disableDefaultUi: false,
            },
            languages: [
                {
                    flag: "images/flags/us.jpg",
                    language: "en",
                    title: "English",
                },
                {
                    flag: "images/flags/nepal.png",
                    language: "np",
                    title: "Nepali",
                },
            ],
            logo: "images/logo.png",
            lan: i18n.locale,
            text: null,
            flag: null,
            value: null,
            submitting: false,
        };
    },
    validations: {
        form: {
            name: {required},
            category: {required},
            contact: {required},
        },
    },
    computed: {
        shouldHavePrice() {
            return this.form.category === 2 || this.form.category === 13;
        }
    },
    methods: {
        createBusiness() {
            axios.get('api/business/create')
                .then((response) => {
                    const {categories} = response.data
                    this.categories = categories
                })
        },
        getPreviewUrl(index) {
            const previewAttachment = this.previewAttachments.find((item) => item.index === index);
            return previewAttachment ? previewAttachment.url : 'https://via.placeholder.com/500';
        },
        onFilePick(index) {
            this.selectedIndex = index
            this.$refs[`upload_${index}`].click();
        },
        onMediaPicked(e) {
            const files = e.target.files;
            if (!files) {
                this.$refs[`upload_${this.selectedIndex}`].value = null;
                return;
            }
            const fr = new FileReader();
            fr.readAsDataURL(files[0]);
            fr.addEventListener('load', () => {
                this.form.attachments.push({index: this.selectedIndex, file: files[0]});
                this.previewAttachments.push({index: this.selectedIndex, url: fr.result});
            })
        },
        //detects location from browser
        geolocate() {
            navigator.geolocation.getCurrentPosition((position) => {
                this.marker.position = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                this.panToMarker();
            });
        },

        //sets the position of marker when dragged
        handleMarkerDrag(e) {
            this.marker.position = {lat: e.latLng.lat(), lng: e.latLng.lng()};
        },
        //Moves the map view port to marker
        panToMarker() {
            this.$refs.mapRef.panTo(this.marker.position);
            this.$refs.mapRef.setZoom(18);
        },
        //Moves the marker to click position on the map
        handleMapClick(e) {
            this.marker.position = {lat: e.latLng.lat(), lng: e.latLng.lng()};
        },
        storeBusiness() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$awn.alert('Error! Please fill all the required fields');
                return;
            }

            this.submitting = true
            const {lat, lng} = this.marker.position
            let formData = new FormData();

            this.form.attachments.forEach((item, index) => {
                formData.append(`attachment${index + 1}`, item.file);
                formData.append(`attachment${index + 1}_index`, item.index);
            })

            formData.append('name', this.form.name)
            formData.append('category', this.form.category)
            formData.append('price', JSON.stringify(this.form.price))
            formData.append('business_size', this.form.business_size)
            formData.append('contact', this.form.contact)
            formData.append('location', JSON.stringify({latitude: lat, longitude: lng}))

            axios.post('api/business', formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(response => {
                const {message} = response.data
                this.$awn.success(message);
                this.form.category = null;
                this.form.name = null;
                this.form.business_size = 'Normal';
                this.form.attachments = [];
                this.previewAttachments = [];
                this.form.contact = null;
            }).catch(error => {
                this.$awn.alert(error.response.data.message ? error.response.data.message : error.message);
            }).finally(() => {
                this.submitting = false
            })
        },
        setLanguage(locale, country, flag) {
            this.lan = locale;
            this.text = country;
            this.flag = flag;
            i18n.locale = locale;
            localStorage.setItem("locale", locale);
        },
        getCurrentLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    this.successCallback,
                    this.errorCallback
                );
            } else {
                this.error = 'Geolocation is not supported by this browser.';
            }
        },
        successCallback(position) {
            const {latitude, longitude} = position.coords
            this.form.location = {
                latitude: latitude,
                longitude: longitude
            };
            this.marker.position = {lat: latitude, lng: longitude}
        },
        errorCallback(error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    this.error = 'User denied the request for Geolocation.';
                    break;
                case error.POSITION_UNAVAILABLE:
                    this.error = 'Location information is unavailable.';
                    break;
                case error.TIMEOUT:
                    this.error = 'The request to get user location timed out.';
                    break;
                case error.UNKNOWN_ERROR:
                    this.error = 'An unknown error occurred.';
                    break;
            }
        }
    },
    mounted() {
        this.getCurrentLocation()
        this.createBusiness()
        this.value = this.languages.find((x) => x.language === i18n.locale);
        this.text = this.value.title;
        this.flag = this.value.flag;
    },
};
</script>
