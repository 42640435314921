require('./bootstrap');

import Vue from "vue";

import {BootstrapVue} from "bootstrap-vue";
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from "./i18n";
import Vuelidate from "vuelidate";
import VueAWN from "vue-awesome-notifications";
import 'vue-awesome-notifications/dist/styles/style.css';
let options = {position: "top-right"}

Vue.use(BootstrapVue);
Vue.use(Vuelidate);

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyB6hEXR9tMfApW3zPY2rt69t-HdBVAyGls",
    },
    installComponents: true,
});

Vue.use(VueAWN, options)
window.Vue = Vue;

Vue.component('index-form', require('./components/Form/IndexForm.vue').default);
Vue.component('destination-index', require('./views/Destination/index.vue').default);
Vue.component('destination-form', require('./views/Destination/form.vue').default);
Vue.component('category-form', require('./views/Category/form.vue').default);
Vue.component('countdown', require('./components/Lunch/Countdown.vue').default);



const app = new Vue({
    i18n,
    el: "#app",
});
