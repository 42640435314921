<template>
    <div class="col-lg-12">
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-body">
                        <b-form class="p-2">
                            <b-form-group id="category-group" label-for="name" class="mb-1">
                                <label>{{ $t('common.name') }}<span class="text-danger">*</span></label>
                                <b-form-input
                                    id="name"
                                    v-model="form.name"
                                    name="name"
                                    type="text"
                                    placeholder="Category Name"
                                    :class="{ 'is-invalid':$v.form.name.$error }"
                                ></b-form-input>
                                <div v-if="$v.form.name.$error" class="invalid-feedback">
                                      <span v-if="!$v.form.name.required">
                                          {{ $t('common.is_required') }}
                                      </span>
                                </div>
                            </b-form-group>
                            <div class="row">
                                <div
                                    :class="!getSubCategories().length ? 'col-12' : 'col-6'">
                                    <b-form-group id="category-group" label-for="category" class="mb-1">
                                        <label>{{ $t('common.category') }}</label>
                                        <b-form-select
                                            v-model="form.category"
                                            :options="categories"
                                            text-field="name"
                                            value-field="id"
                                            class="form-control">
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                                <div class="col-6"
                                     v-if="getSubCategories().length > 0">
                                    <b-form-group id="category-group" label-for="category" class="mb-1">
                                        <label>{{ $t('common.sub_category') }}</label>
                                        <b-form-select
                                            v-model="form.sub_category"
                                            :options="getSubCategories()"
                                            text-field="name"
                                            value-field="id"
                                            class="form-control">
                                        </b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <b-form-group id="destination-group" label-for="name" class="mb-1">
                                <label>{{ $t('common.short_description') }}</label>
                                <b-form-textarea
                                    id="name"
                                    v-model="form.short_description"
                                    name="name"
                                    type="text"
                                    placeholder="Short Description"
                                ></b-form-textarea>
                            </b-form-group>
                            <b-form-group id="destination-group" label-for="description" class="mb-1">
                                <label>{{ $t('common.description') }}</label>
                                <b-form-textarea
                                    id="description"
                                    v-model="form.description"
                                    name="description"
                                    type="text"
                                    placeholder="Detail Description"
                                    rows="5"
                                ></b-form-textarea>
                            </b-form-group>
                        </b-form>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <b-form-group label-for="status" class="mb-1">
                            <b-form-checkbox
                                id="status"
                                v-model="form.status"
                                name="status"
                                value="true"
                                unchecked-value="false"
                                class="d-flex align-items-center"
                            >
                                <span class="mx-2">Publish ?</span>
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group label-for="show_in_dashboard" class="mb-1">
                            <b-form-checkbox
                                id="show_in_dashboard"
                                v-model="form.show_in_dashboard"
                                name="show_in_dashboard"
                                value="true"
                                unchecked-value="false"
                                class="d-flex align-items-center"
                            >
                                <span class="mx-2">Show in dashboard?</span>
                            </b-form-checkbox>
                        </b-form-group>
                        <b-form-group id="caption" label-for="caption" class="mb-1">
                            <label>{{ $t('common.caption') }}<span class="text-danger">*</span></label>
                            <b-form-input
                                id="caption"
                                v-model="form.caption"
                                name="caption"
                                type="text"
                                placeholder="Eg: Featured Places"
                                :class="{ 'is-invalid':$v.form.caption.$error }"
                            ></b-form-input>
                            <div v-if="$v.form.caption.$error" class="invalid-feedback">
                                  <span v-if="!$v.form.caption.required">
                                      {{ $t('common.is_required') }}
                                  </span>
                            </div>
                        </b-form-group>
                        <b-form-group id="destination-group" label-for="name" class="mb-1">
                            <label>{{ $t('common.display_order') }}</label>
                            <b-form-input
                                id="display_order"
                                v-model="form.display_order"
                                name="display_order"
                                type="number"
                                placeholder="Eg: 1"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="d-flex justify-content-end align-items-center mb-1">
                    <b-button variant="danger" class="me-2" @click.prevent="previewImage = defaultImage">Reset
                    </b-button>
                    <b-button variant="primary" @click.prevent="onFilePick">Upload</b-button>
                    <input
                        id="avatar"
                        type="file"
                        multiple
                        accept="image/*"
                        style="display: none"
                        ref="upload"
                        @change="onMediaPicked">
                </div>
                <div class="avatar-xxl">
                    <img
                        :src="previewImage !== null ? previewImage : defaultImage"
                        width="100%" height="225"
                        class="rounded me-2 object-fit-cover" alt="preview">
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end align-items-center mt-5">
            <b-button
                variant="primary" class="me-2"
                @click.prevent="editMode ? updateCategory() : storeCategory()">
                Save Changes
            </b-button>
        </div>
    </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
    data() {
        return {
            form: {
                name: null,
                short_description: null,
                description: null,
                category: null,
                sub_category: null,
                status: true,
                show_in_dashboard: false,
                display_order: null,
                caption: null,
            },
            previewImage: null,
            submitting: false,
            defaultImage: "/images/default.png",
            editMode: false,
        };
    },
    validations: {
        form: {
            name: {required},
            caption: {required},
        },
    },
    props: ['categories', 'category'],
    methods: {
        getSubCategories() {
            if (!this.form.category || !Array.isArray(this.categories)) {
                return [];
            }

            const category = this.categories.find(x => x.id === this.form.category);
            return category ? category.children : [];
        },
        onFilePick() {
            this.$refs.upload.click();
        },
        onMediaPicked(e) {
            const files = e.target.files;
            if (!files) {
                this.$refs.upload.value = null;
                return;
            }
            const file = files[0];
            const fr = new FileReader();
            fr.readAsDataURL(file);
            fr.addEventListener('load', () => {
                this.form.attachment = file;
                this.previewImage = fr.result;
            })
        },
        storeCategory() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$awn.alert('Error! Please fill all the required fields');
                return;
            }

            this.submitting = true
            let formData = new FormData();

            formData.append('attachment', this.form.attachment);
            formData.append('name', this.form.name)
            formData.append('caption', this.form.caption)
            formData.append('short_description', this.form.short_description)
            formData.append('description', this.form.description)
            formData.append(
                'category',
                this.form.sub_category ? this.form.sub_category :
                    this.form.category
            );
            formData.append('status', this.form.status)
            formData.append('show_in_dashboard', this.form.show_in_dashboard)
            formData.append('display_order', this.form.display_order)

            axios.post('/admin/category', formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(response => {
                const {message} = response.data
                this.$awn.success(message);
                window.location.href = '/admin/category'
            }).catch(error => {
                this.$awn.alert(error.response.data.message ? error.response.data.message : error.message);
            }).finally(() => {
                this.submitting = false
            })
        },
        editCategory() {
            if (this.category) {
                this.editMode = true;
                this.form.name = this.category.name
                this.form.short_description = this.category.short_description
                this.form.description = this.category.description
                this.form.category = this.category.category
                this.form.status = this.category.status === 1
                this.form.show_in_dashboard = this.category.show_in_dashboard === 1
                this.form.display_order = this.category.display_order
                this.form.caption = this.category.caption
                this.previewImage = `/uploads/category/${this.category.image}`;

                if (this.category.parent_id) {
                    const index = this.categories.findIndex(x => x.id === Number.parseInt(this.category.parent_id));

                    if (index !== -1) {
                        this.form.category = this.categories[index].id
                        const subCategoryIndex = this.categories[index].children.findIndex(x => x.id === this.category.id);
                        this.categories[index].children.splice(subCategoryIndex, 1)
                    } else {
                        const subCategory = this.findItemById(this.categories, Number.parseInt(this.category.parent_id))
                        this.form.sub_category = subCategory.id
                        this.form.category = this.findItemById(this.categories, Number.parseInt(subCategory.parent_id)).id
                    }
                }
            }
        },
        findItemById(items, parent_id) {
            for (const item of items) {
                if (item.id === parent_id) {
                    return item;
                }
                if (item.children && item.children.length > 0) {
                    const foundItem = this.findItemById(item.children, parent_id);
                    if (foundItem) {
                        return foundItem; // Return the found item if exists in children
                    }
                }
            }
        },
        updateCategory() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$awn.alert('Error! Please fill all the required fields');
                return;
            }

            this.submitting = true
            let formData = new FormData();

            formData.append('attachment', this.form.attachment);
            formData.append('name', this.form.name)
            formData.append('caption', this.form.caption)
            formData.append('short_description', this.form.short_description)
            formData.append('description', this.form.description)
            formData.append(
                'category',
                this.form.sub_category ? this.form.sub_category :
                    this.form.category
            );
            formData.append('status', this.form.status)
            formData.append('show_in_dashboard', this.form.show_in_dashboard)
            formData.append('display_order', this.form.display_order)

            axios.post(`/admin/category/${this.category.id}?_method=PATCH`, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(response => {
                const {message} = response.data
                this.$awn.success(message);
                window.location.href = '/admin/category'
            }).catch(error => {
                this.$awn.alert(error.response.data.message ? error.response.data.message : error.message);
            }).finally(() => {
                this.submitting = false
            })
        },
    },
    mounted() {
        this.editCategory()
    }
};
</script>
